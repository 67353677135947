import Page from './../component/HeroPage'
import bg from './../assets/images/team-hero-img.jpg'
import Results from './team-coaching/Results'
import Context from './team-coaching/Context'
import { Popup } from 'reactjs-popup'
import { FunctionComponent } from 'react'
import Contact from './../Contact'
import { Helmet } from 'react-helmet'
import { useLocation } from "react-router-dom"

type ContactMePopupProps = {
  trigger: JSX.Element
}

const ContactMePopup: FunctionComponent<ContactMePopupProps> = ({ trigger, children }) => {
  const reportOpenEventToAnalytics =
    () => window.gtag("event", "view_item", { id: "team-coaching-contact-me", name: "Нажал на кнопку обратной связи на странице 'Командный коучинг'" })

  return (
    <Popup trigger={trigger} modal nested onOpen={reportOpenEventToAnalytics}>
      {(_: () => any) => (
        <div className="content">{children}</div>
      )}
    </Popup>
  )
}

const TeamCoaching = () => {
  const loc = useLocation()
  const children = (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Валентина Перерва - Командный коучинг</title>
        <link rel="canonical" href={`https://perervacoach.com${loc.pathname}`} />
      </Helmet>
      <div className="navbar-scroll">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div id="scroll-nav">
                <ul>
                  <li><a href="#goals">Результаты</a></li>
                  <li><a href="#context">Факты</a></li>
                  <li><a href="#when">Когда проводить</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-lg-16 py-8 " id="goals">
        <div className="container">
          <div className="row align-items-center">
            <Results />
          </div>
        </div>
      </div>
      <div className="pt-lg-20 pb-lg-12 py-12 bg-secondary top-shape mt-lg-n13 mt-n8 " id="context" >
        <div className="container">
          <div className="row">
            <Context />
          </div>
        </div>
      </div>
      <div className="py-lg-16 py-10  bg-light" id="when" >
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-md-12 col-12 mb-8 text-center">
              <h2>Когда проводить командный коучинг?</h2>
              <p className="lead">
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="bg-white mb-6 rounded-bottom">
                <div className="card-body p-6">
                  <div className="pb-4">
                    <h4>Загрузка новых комманд</h4>
                    <p className="mb-0">
                      Новая команда только сформирована и ей необходимо дать правильное направление развития и энергию
                    </p>
                  </div>
                  <div className="pb-4 pt-4 border-top">
                    <h4>При необходимости повышения эффективности команды</h4>
                    <p className="mb-0">
                      Команда не работает настолько эффективно, как могла бы, а лидеры и члены команды согласны с тем, что они хотят существенных улучшений
                    </p>
                  </div>
                  <div className="pb-0 pt-4 border-top">
                    <h4>Перезагрузка существующих команд</h4>
                    <p className="mb-0">
                      Давно сложившаяся команда потеряла свою энергию и хочет найти новые пути повышения продуктивности и эффективности
                    </p>
                  </div>
                  <div className="pb-4 pt-4 border-top">
                    <h4>Существует спрос на лидерство</h4>
                    <p className="mb-0">
                      Топ-команда хочет стать примером для подражания для остальной части компании, организации
                    </p>
                  </div>
                  <div className="pb-4 pt-4 border-top">
                    <h4>Отсутсвие видения</h4>
                    <p className="mb-0">
                      В команде отсутствует единое видение или понимание совместных задач. Необходимо сформировать миссию, которая будет мотивировать участников
                    </p>
                  </div>
                  <div className="pb-4 pt-4 border-top">
                    <h4>В команде отсутствует концентрация</h4>
                    <p className="mb-0">
                      В коллективе наблюдается низкая сплоченности команды. Конфликты в коллективе препятствуют продуктивной совместной работе. Каждый сотрудник тянет одеяло на себя, в результате общие цели не выполняются
                    </p>
                  </div>
                  <div className="pb-4 pt-4 border-top">
                    <h4>Отсутсвие командного духа</h4>
                    <p className="mb-0">
                      Отсутствует командный дух и/или мотивация к работе
                    </p>
                  </div>
                  <div className="pb-4 pt-4 border-top">
                    <h4>Необходимость слаженной работы</h4>
                    <p className="mb-0">
                      Перед командой стоят амбициозные цели и необходимо организовать высокоэффективную слаженную работу. Необходимо выявить проблемы, препятствующие работе в коллективе и сформировать способы их решения
                    </p>
                  </div>
                  <div className="pb-4 pt-4 border-top">
                    <h4>Низкая профессиональная этика</h4>
                    <p className="mb-0">
                      В коллективе нужно создать культуру ответственности за результат. Затруднены коммуникации между отделами или внутри отдела. Проблемы с вовлеченностью сотрудников препятствуют работе
                    </p>
                  </div>
                  <div className="pb-4 pt-4 border-top">
                    <h4>Низкая сплоченность в команде </h4>
                    <p className="mb-0">
                      Необходимо сплочение членов команды для достижения общего результата
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="offset-lg-3 col-lg-6 col-md-6 col-12 mt-8 mb-20 text-center">
              <ContactMePopup trigger={<button className="btn btn-warning btn-lg">Свяжитесь со мной</button>}>
                <Contact />
              </ContactMePopup>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const lead = (
    <blockquote className="blockquote text-right">
      <p className="mb-0">
        В управлении производством в конечном счете все сводится к трем факторам: люди, продукты, прибыль. На первом месте стоят люди. Если у вас нет надежной команды, то из остальных факторов мало что удастся сделать</p>
      <footer className="blockquote-footer">Ли Яккока</footer>
    </blockquote>
  )

  return (<Page lead={lead} heroBgImg={bg} title="Командный коучинг" >{children}</Page>)
}

export default TeamCoaching
