import './../App.css';
import speaker from './../assets/images/_main_portrait.jpg';
import shape from './../assets/images/shape.svg';
import { Helmet } from 'react-helmet';
import acc from "./../assets/images/certs/thumb/acc.png";
import acsth from "./../assets/images/certs/thumb/acsth.png";
import icf from "./../assets/images/certs/thumb/icf-membership.png";
import ipc from "./../assets/images/certs/thumb/ipc.jpg";
import mediation from "./../assets/images/certs/thumb/mediation.png";
import tcpc from "./../assets/images/certs/thumb/tcpc.jpg";
import accF from "./../assets/images/certs/acc.png";
import acsthF from "./../assets/images/certs/acsth.png";
import icfF from "./../assets/images/certs/icf-membership.png";
import ipcF from "./../assets/images/certs/ipc.jpg";
import mediationF from "./../assets/images/certs/mediation.png";
import tcpcF from "./../assets/images/certs/tcpc.jpg";
import Contact from "./../Contact";
import YouTubePopup from './../YoutubePopup';
import Services from "./../Services";
import CertPanel from "./../Cert";

function Landing() {
  const certs: [string, string, string][] = [
    [acc, accF, "Assaciate Certified Coach (ACC)™"],
    [acsth, acsthF, "Erickson Prefessional Coach"],
    [icf, icfF, "ICF Membership"],
    [ipc, ipcF, "INTERNATIONAL-LEVEL PROFESSIONAL COACH"],
    [mediation, mediationF, "Mediation"],
    [tcpc, tcpcF, "Team Coaching Practitioner"]
  ];

  const popUpTrigger = (
    <a className="icon-shape rounded-circle btn-play icon-md bg-secondary me-1 text-decoration-none font-12">
      <i className="fas fa-play"></i>
    </a>
  )

  const popUpTriggerText = (<a className="ms-1 hover-pointer text-white">Обо мне</a>)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Валентина Перерва</title>
        <link rel="canonical" href="https://perervacoach.com/" />
      </Helmet>
      <div className="bg-dark right-slant-shape pt-lg-16 py-12 pb-lg-0 ">
        <div className="container">
          <div className="row d-lg-flex align-items-center ">
            <div className="col-lg-5 col-lg-5 col-md-6 col-12">
              <div className="mb-0 mb-lg-8 ">
                <h1 className=" text-white mb-3 display-4 font-weight-bold">
                  Индивидуальный и <a href="https://icoach.team/?fbclid=IwAR0L03arVkD5YU612CcDlfTBRTNjeb3dhQYwI1kTYeHNcKPIwRWJXWH2wt0#rec293004856" target="_blank" rel="alternate"> командный коучинг</a>
                </h1>
                <p className="mb-6 lead">
                  Работая со мной, вы научитесь видеть неожиданное в привычном и привычное в неожиданном, обретёте ясность, ментальную силу, перспективу, стратегию, прорыв
                </p>
                {/* <a href="#" className="btn btn-primary me-3">Связаться</a> */}
                <YouTubePopup videoId="M7nlvI0vXPM" triggerChild={popUpTrigger} />
                <YouTubePopup videoId="M7nlvI0vXPM" triggerChild={popUpTriggerText} />
              </div>
            </div>
            <div className="offset-xl-2 col-xl-5 offset-lg-1 col-lg-5 col-md-6 col-12 mb-n2 d-none d-md-block">
              <div className="position-relative z-index-1">
                <img src={speaker} alt="" className="img-fluid rounded w-100 border-bottom border-bottom-4 border-primary" />
                <div className="card-img-overlay d-flex flex-column justify-content-end p-4 title">
                  <h4 className="mb-0 h2">Валентина Перерва</h4>
                  <span className="font-18"><a className="decorate" href="https://www.youracclaim.com/badges/d87eb5bb-6a24-4b86-9fda-4447477c1c43/embedded" target="_blank" rel="noreferrer">Коуч ACC</a></span>
                </div>
              </div>
              <div className="position-relative z-index-0">
                <div className=" position-absolute ms-n14 mt-n7">
                  <img src={shape} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CertPanel items={certs} />
      {/* <Programs /> */}
      <Services />
      <Contact />
    </>
  );
}

export default Landing
