import teamCoaching1 from './../../assets/images/team-coaching1.jpg'
import teamCoaching2 from './../../assets/images/ft-img-1.jpg'
import teamCoaching3 from './../../assets/images/showcase-img-1.jpg'


export default () => (
  <>
    <div className="col-lg-6 col-md-12 col-sm-6 col-12">
      <h2 className="mb-4">Результаты командного коучинга</h2>
      <p>
        Командный коучинг – формат групповой работы, позволяющий активизировать потенциал каждого сотрудника и команды в целом ради достижения общих целей. В процессе сессий командного коучинга команда профессионалов (сотрудников одного отдела, руководителей, менеджеров проектов и др.) с помощью коуча проводит ревизию существующего положения вещей по параметрам эффективности, намечает необходимые изменений и тщательно планирует все необходимые действия ради достижения коллективного результата.
      </p>

      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <div className="mb-4">
            <h4 className="h5">
              <span className="text-danger mr-2">01</span>Общая миссия и видение
            </h4>
            <p>
              Появление общей миссии и видения, которые мотивируют сотрудников на продуктивную работу
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <div className="mb-4">
            <h4 className="h5">
              <span className="text-danger mr-2">02</span>Общие ценности
            </h4>
            <p>
              Выявление общих для всей команды ценностей, и как следствие – правил и подходов к работе, обеспечивающих высокий результат
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-0">
          <div className="mb-4">
            <h4 className="h5">
              <span className="text-danger mr-2">03</span>Повышение удовлетворенности работой
            </h4>
            <p>
              Повышение удовлетворенности работой и улучшение отношений в коллективе
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <div className="mb-4">
            <h4 className="h5">
              <span className="text-danger mr-2">04</span>Снижение конфликтности
            </h4>
            <p>
              Снижение конфликтности в коллективе и формирование взаимных договоренностей
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <div className="mb-4">
            <h4 className="h5">
              <span className="text-danger mr-2">05</span>Повышение показателей эффективности команды
            </h4>
            <p>
              Повышение общей эффективности работы команды, в том числе через создание культуры ответственности и рост сплоченности. Повышение вовлеченности сотрудников в работу и улучшение качества коммуникации
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <div className="mb-4">
            <h4 className="h5">
              <span className="text-danger mr-2">06</span>Планирование
            </h4>
            <p>
              Составление стратегии и плана достижения целей. Подготовка детализированного дерева задач со сроками и ответственными
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
          <div className="mb-4">
            <h4 className="h5">
              <span className="text-danger mr-2">07</span>Повышение креативности
            </h4>
            <p>
              Генерация новых идей и подходов в областях, необходимых бизнесу
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-6 col-md-12 col-sm-6 col-12">
      <div className="row justify-content-center g-0">
        <div className="col-6 mr-n4 mr-md-n12 ml-lg-8 ">
          <img src={teamCoaching1} alt="..." className="img-fluid mb-3 rounded " />

          <img src={teamCoaching2} alt="..." className="img-fluid rounded  " />
        </div>
        <div className="col-6 ">
          <img src={teamCoaching3} alt="..." className="img-fluid mb-3 rounded  " />

          <img src={teamCoaching3} alt="..." className="img-fluid rounded  " />
        </div>
      </div>
    </div>
  </>
)
