export default () => {
  return (
    <>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-5 mb-md-0">
          <div className="h2 mb-4">
            <i className="fas fa-lock bg-white icon-shape icon-xl bg-white rounded-lg text-secondary"></i>
          </div>
          <p className="text-white">
            По отзывам   коллег – командных коучей с опытом, эффективность работы в компании падает за счет того, что сотрудники, даже будучи высокими профессионалами, не могут обеспечить высокоэффективную работу отдела, направления или проектной группы, потому что у них отсутствует ряд параметров, необходимых для качественной командной работы
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-5 mb-md-0">
          <div className="h2 mb-4">
            <i className="fas fa-paper-plane bg-white icon-shape icon-xl bg-white rounded-lg text-secondary"></i>
          </div>
          <p className="text-white">
            Основные показатели эффективности комманды: правильное понимание и распределение ролей в команде, наличие общего видения, культура, помогающая достигать общий результат, разделяемые всей командой ценности, четкое понимание целей и задач деятельности, правильно отстроенные бизнес-процессы и многое другое. И здесь очень эффективен командный коучинг, он то важное звено без которого не обойтись, если ориентироваться на развитие и повышение прибыли, результативности, качественный ответ на постоянно возникающие вызовы. Уникальность командного коучинга состоит в том, что он работает не с каждым человеком по отдельности, а с той плазмой, с теми отношениями, которые есть между людьми
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-5 mb-md-0">
          <div className="h2 mb-4">
            <i className="fas fa-users bg-white icon-shape icon-xl bg-white rounded-lg text-secondary"></i>
          </div>
          <p className="text-white">
            Примечательным является то, что термины «команда» и «группа» часто используют как тождественные понятия. Однако между ними существует разница. В команду, как правило, входит от 3-х до 12 человек, которые:
          </p>
          <ul className="text-white">
            <li>совместно работают над выполнением общих задач</li>
            <li>имеют одно руководство</li>
            <li>сообща несут ответственность за результат</li>
            <li>видят себя частью команды с общими целями и судьбой</li>
          </ul>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-5 mb-md-0">
          <div className="h2 mb-4">
            <i className="fas fa-map bg-white icon-shape icon-xl bg-white rounded-lg text-secondary"></i>
          </div>
          <p className="text-white">
            Коуч не рассказывает, как правильно нужно строить коммуникацию или избежать конфликтов, участники команды сами под чутким методологическим руководством  командного коуча сначала сами намечают, какие именно задачи они планируют решить во время сессии, а затем – выбирают способы, которыми они эти задачи будут решать, перенимая у коуча через опыт взаимодействия в процессе коучингового взаимодействия эффективные навыки выстраивания трудовых процессов во взаимодействии и коллективном усилении друг друга на пути достижения целей компании
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-5 mb-md-0">
          <div className="h2 mb-4">
            <i className="fas fa-cubes bg-white icon-shape icon-xl bg-white rounded-lg text-secondary"></i>
          </div>
          <p className="text-white">
            Вся работа проводится с помощью раскрытия внутреннего потенциала сотрудников, а не знаний приглашенного тренера, консультанта, ментора. В этом случае ответственность участников за поставленные цели, запланированные задачи или правила коммуникации команды многократно повышается
          </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-5 mb-md-0">
          <div className="h2 mb-4">
            <i className="fab fa-searchengin bg-white icon-shape icon-xl bg-white rounded-lg text-secondary"></i>
          </div>
          <p className="text-white">Где используется командный коучинг:</p>
          <ul className="text-white">
            <li>В Проектной деятельности</li>
            <li>Для повышения эффективности команды ТОП менеджеров</li>
            <li>Для повышения эффективности коммерческих подразделений (подразделений продаж)</li>
            <li>Для повышения уровня взаимодействия между подразделениями</li>
          </ul>
        </div>
      </div>
    </>
  )
}
