import { FunctionComponent } from 'react'
import { Popup } from 'reactjs-popup'
import YouTube, { Options } from 'react-youtube'

const opts: Options = {
  /* width: '853',
   * height: '505', */
  playerVars: {
    autoplay: 1,
  },
};

type YouTubePopupProps = {
  videoId: string
  triggerChild: JSX.Element
}

const YouTubePopup: FunctionComponent<YouTubePopupProps> = ({ videoId, triggerChild }) => {
  const reportOpenEventToAnalytics =
    () => window.gtag("event", "view_item", { id: "welcome-video-view", name: "Кто-то посмотрел видео на главной странице ${videoId}" })

  return (
    <Popup trigger={triggerChild} modal nested onOpen={reportOpenEventToAnalytics}>
      {(_: () => any) => (
        <div className="other-modal">
          <div className="content">
            <YouTube videoId={videoId} opts={opts} />
          </div>
        </div>
      )}
    </Popup >
  )
}

export default YouTubePopup
