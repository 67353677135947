import { useState } from "react"
import { Link } from "react-router-dom"
import './../assets/theme.scss'

function Navbar() {
  const [isCollapsed, setCollapsed] = useState(true)

  const onToggle = (_: any) => {
    setCollapsed(!isCollapsed)
  }

  const navbarCollapsedClass = (isCollapsed && "collapsed") || ""
  const menuCollapsedClass = (isCollapsed && "collapse") || ""

  return (
    <div className="header header-collapse fixed-top border-top-3 border-top border-primary float-end">
      <div className="container-lg">
        <nav className="navbar navbar-expand-lg navbar-default float-end">
          <button
            onClick={onToggle}
            className={`navbar-toggler ${navbarCollapsedClass}`}
            type="button"
            data-toggle="collapse"
            data-target="#navbar-default"
            aria-controls="navbar-default"
            aria-expanded="false"
            aria-label="Меню">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>
          <div className={`${menuCollapsedClass} navbar-collapse`} id="navbar-defaul">
            <button
              className="navbar-toggler collapsed"
              onClick={onToggle}
              type="button"
              data-toggle="collapse"
              data-target="#navbar-default"
              aria-controls="navbar-default"
              aria-expanded="false"
              aria-label="Меню">
              <i className="fas fa-times"></i>
            </button>
            <ul className="navbar-nav ml-auto mr-lg-3 ">
              <li className="nav-item dropdown disabled">
                <a className="nav-link d-lg-none" href="#">
                  Меню
              </a>
              </li>
              <li className="nav-item dropdown ">
                <Link to="/" className="nav-link">Главная</Link>
              </li>
              <li className="nav-item dropdown ">
                <Link to="/" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" data-display="static">О коучинге</Link>
                <ul className="dropdown-menu dropdown-menu-md dropdown-menu-arrow dropdown-menu-right dropdown-menu-xl-left show" aria-labelledby="menu-4">

                  <li className="dropdown-submenu">
                    <Link to="/coaching/team-coaching" className="dropdown-list-group-item dropdown-toggle">
                      <h4 className="h5">Командный коучинг<span className="text-primary font-12 ml-1"> (Новинка)</span></h4>
                      <p className="text-muted mb-0 font-12">
                        Формат групповой работы
                    </p>
                    </Link>
                  </li>
                  {/* <li className="dropdown-submenu">
                      <a className="dropdown-list-group-item dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <h4 className="h5">Мышление роста<span className="text-primary font-12 ml-1"> (Новинка)</span></h4>
                      <p className="text-muted mb-0 font-12">
                      Инструмент вашего успеха
                      </p>
                      </a>
                      </li>
                      <li>
                      <a className="dropdown-list-group-item" href="@@webRoot/pages/book-download-page.html">
                      <h4 className="h5">Мастермайнд<span className="text-primary font-12 ml-1"> (Новинка)</span></h4>
                      <p className="text-muted mb-0 font-12">
                      Апгрейд вашей силы
                      </p>
                      </a>
                      </li> */}
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div >
  )
}

export default Navbar
