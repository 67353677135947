import { FunctionComponent } from 'react';

type CertImgProps = {
  img: string,
  fullImg: string,
  altText: string
}

const CertImg: FunctionComponent<CertImgProps> = ({ img, fullImg, altText }) => {
  const onCertView = (name: string) => {
    return () => {
      window.gtag("event", "view_item", { id: name, name: fullImg })
    }
  }

  return (
    <div className="col-lg-2 col-md-4 col-6">
      <div className="mb-4">
        <a href={fullImg} onClick={onCertView(altText)} target="_blank" rel="noreferrer">
          <img src={img} alt={altText} />
        </a>
        <p>{altText}</p>
      </div>
    </div>
  )
}

type CertPanelProps = {
  items: [string, string, string][]
}

const CertPanel: FunctionComponent<CertPanelProps> = ({ items }) => {
  const images = items.map(([i, f, d]) => <CertImg key={d} img={i} fullImg={f} altText={d}></CertImg>)

  return (
    <div className="py-7 pt-lg-18 pb-lg-8 certifications">
      <div className="container">
        <div className="row">{images}</div>
      </div>
    </div>
  )
}

export default CertPanel
