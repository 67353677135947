import { FunctionComponent, FormEvent, createRef, useState, useEffect } from 'react'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { of } from 'rxjs'

const Contact: FunctionComponent = () => {
  const nameInput = createRef<HTMLInputElement>()
  const emailIput = createRef<HTMLInputElement>()
  const messageInput = createRef<HTMLTextAreaElement>()
  const inputs = [nameInput, emailIput, messageInput]
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (submitted) {
      inputs.forEach(el => {
        if (el.current)
          el.current.value = ""
      })
    }
  }, [submitted])

  useEffect(() => {
    inputs.forEach(el => {
      if (el.current)
        el.current.disabled = submitting
    })
  }, [submitting])

  const getSubmittedValues = () => inputs.reduce((result: { [key: string]: string }, el) => {
    if (el.current)
      result[el.current.name] = el.current?.value
    return result
  }, {})

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault()
    console.log(getSubmittedValues())
    setSubmitting(true)
    const res = ajax({
      url: "/api/contact-us",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: getSubmittedValues()
    }).pipe(
      map(_ => {
        setSubmitted(true)
        setSubmitting(false)
        return true
      }),
      catchError(error => {
        setSubmitting(false)
        console.log('error', error)
        return of(false)
      })
    )
    res.subscribe(res => console.log('info', `Form submitted - ${res}`))
  }

  const button =
    (submitting && (
      <div className="col-12 text-end">
        <div className="spinner-border text-secondary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )) || (submitted && (
      <div className="col-12">
        <div className="alert alert-success  mb-0" role="alert">
          Отправлено
        </div>
      </div>
    )) || (
      <div className="col-12 text-end">
        <button type="submit" className={`btn btn-primary btn-block`}>
          Отправить
        </button>
      </div>)

  return (
    <div className="pt-0 pb-0">
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 col-lg-8 col-md-12 col-12 mb-5">
            <div className="card z-index-1 contact-me">
              <div className="card-body p-4 p-lg-9">
                <div className="mb-8 text-center">
                  <h2 className="mb-3">Давайте оставаться на свази</h2>
                  <p>
                    Отправьте сообщение, заполнив форму, и я свяжусь с Вами в течение 24 часов
                  </p>
                </div>
                <form className="row" onSubmit={onFormSubmit}>
                  <div className="from-group col-12 col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">Ваше имя</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Имя"
                      name="name"
                      ref={nameInput}
                    />
                  </div>

                  <div className="from-group col-12 col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">Адрес электронной почты</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Имейл"
                      name="email"
                      ref={emailIput}
                    />
                  </div>
                  <div className="mb-3 col-12 col-md-12 mb-4">
                    <label htmlFor="message" className="form-label">Текст сообщения</label>
                    <textarea
                      id="message"
                      className="form-control "
                      placeholder="Ваше сообщение"
                      rows={6}
                      name="message"
                      ref={messageInput}
                    />
                  </div>
                  {button}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
