import * as CSS from 'csstype'
import { FunctionComponent } from 'react'

type HeroPageProps = {
  heroBgImg: string
  title: string
  lead: JSX.Element
}

const HeroPage: FunctionComponent<HeroPageProps> = ({ heroBgImg, title, lead, children }) => {
  const heroBg: CSS.Properties = {
    "backgroundImage":
      `linear-gradient(180deg, rgba(30, 24, 53, 0.4) 0%, rgba(30, 24, 53, 0.4) 90.16%), url(${heroBgImg})`
  }

  return (
    <>
      <div className="bg-cover d-flex align-items-center" style={heroBg}>
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-md-12 col-12">
              <div className="py-10 py-lg-19 text-light text-center">
                <h1 className="font-weight-bold text-white mb-3 display-4">
                  {title}
                </h1>
                <div className="mb-4 lead">{lead}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
      <div className="pt-20 pb-0 bg-dark text-light bottom-shape mt-n20  "></div>
    </>
  )
}

export default HeroPage
