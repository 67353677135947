import { useRouteMatch } from "react-router-dom"
import TeamCoaching from './page/TeamCoaching'

const PageRouter = () => {
  const match = useRouteMatch()

  return (<TeamCoaching />)
}

export default PageRouter
