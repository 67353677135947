import './assets/theme.scss'
import Landing from "./page/Landing"
import { BrowserRouter as Router, Route } from "react-router-dom"
import Navbar from "./block/Navbar"
import PageRouter from './PageRouter'

function App() {
  return (
    <Router>
      <Navbar />
      <Route path="/" exact component={Landing} />
      <Route path="/coaching" component={PageRouter} />
    </Router>
  );
}

export default App;
