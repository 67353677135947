import { FunctionComponent } from 'react'

const Services: FunctionComponent = () => {

  return (
    <>
      <div className="pt-lg-9">
        <div className="container">
          <div className="row">
            <div className="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-12">
              <div className="text-center">
                <h2 className="h1">
                  В чем я могу быть вам полезна
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-8">
        <div className="container mt-lg-10 mb-lg-10 bg-light p-5">

          <div className="row pb-3">
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card mb-xl-0 mb-lg-4 mb-4 border-0">
                <div className="card-body p-4">
                  <i className="fas fa-chart-line fa-fw text-primary font-28 mb-4"></i>
                  <h4>Коучинг целей и достижений</h4>
                  <ul className="list-unstyled mb-0">
                    <li>Создание нового качества жизни в период возрастных кризисов</li>
                    <li>Создание новых жизненный сценариев и личных способностей в периоды кризиса личности и ценностей, повышение качества жизни</li>
                    <li>Изменение неэффективных жизненных стратегий и моделей поведения</li>
                    <li>Рефрейминг сознания, мышления, моделей поведения</li>
                    <li>Повышение личной и профессиональной результативности</li>
                    <li>Развитие латерального (с использованием максимального количества подходов к решению задачи) мышления</li>
                    <li>Поиск, повышение мотивации</li>
                    <li>Повышение самооценки</li>
                    <li>Поиск, определение предназначения, выстраивание жизненной стратегии</li>
                    <li>Развитие личного, профессионального, социального лидерства</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card mb-xl-0 mb-lg-4 mb-4 border-0">
                <div className="card-body p-4">
                  <i className="fas fa-user-tie fa-fw text-primary font-28 mb-4"></i>
                  <h4>Управление процессами</h4>
                  <ul className="list-unstyled mb-0">
                    <li>Развитие и усиление навыков делегирования, планирования, тайм менеджмента</li>
                    <li>Создание алгоритма эффективного использования внутренних и внешних ресурсов</li>
                    <li>Выстраивание выигрышных для вас отношений с рутиной</li>
                    <li>Создание стратегий определения зон ответственности</li>
                    <li>Создание дорожной карты профессионального развития и роста</li>
                    <li>Планирование получения новых навыков и знаний</li>
                    <li>Определение шагов для реализации желаемого</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card mb-xl-0 mb-lg-4 mb-4 border-0">
                <div className="card-body p-4">
                  <i className="fas fa-heart fa-fw text-primary font-28 mb-4"></i>
                  <h4>Построение отношений</h4>
                  <ul className="list-unstyled mb-0">
                    <li>Построение новых отношений</li>
                    <li>Гармония в отношениях</li>
                    <li>Жизнь после разрыва отношений</li>
                    <li>Женское одиночество имущих и неимущих</li>
                    <li>Перезагрузка отношений с детьми</li>
                    <li>Коучинг отношений с пожилыми родителями</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card mb-xl-0 mb-lg-4 mb-4 border-0">
                <div className="card-body p-4">
                  <i className="fas fa-business-time fa-fw text-primary font-28 mb-4"></i>
                  <h4>Карьерный коучинг</h4>
                  <ul className="list-unstyled mb-0">
                    <li>Как быть счастливым в работе?</li>
                    <li>Развитие карьеры, решение проблемы застревания на одном месте</li>
                    <li>Изменение профессиональной деятельности для повышения качества жизни</li>
                    <li>Создание новых сфер профессиональной самореализации в возрасте 45+</li>
                    <li>Создание, запуск собственного дела, бизнеса, направления самореализации</li>
                    <li>Сопровождение стартапов для усиления эффективных стратегий</li>
                    <li>Поиск призвания</li>
                    <li>Профориентация подростков</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card mb-xl-0 mb-lg-4 mb-4 border-0">
                <div className="card-body p-4">
                  <i className="fas fa-portrait fa-fw text-primary font-28 mb-4"></i>
                  <h4>Управление собой</h4>
                  <ul className="list-unstyled mb-0">
                    <li>Работа с собственными эмоциями и состояниями</li>
                    <li>Развитие эмоциональной палитры</li>
                    <li>Управление стрессом, снятие стрессовых состояний</li>
                    <li>Самоконтроль. Самомотивация. Самодисциплина</li>
                    <li>Работа с выгоранием (эмоциональным, профессиональным)</li>
                    <li>Сохранение и накопление энергии при высокой интенсивности работы</li>
                    <li>Преодоление внутренних барьеров («хочу, но не могу»)</li>
                    <li>Определение профессиональной роли</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card mb-xl-0 mb-lg-4 mb-4 border-0">
                <div className="card-body p-4">
                  <i className="fas fa-user-friends fa-fw text-primary font-28 mb-4"></i>
                  <h4>Управление другими</h4>
                  <ul className="list-unstyled mb-0">
                    <li>Повышение коммуникативной и управленческой компетенции</li>
                    <li>Успешные переговоры</li>
                    <li>Убедительная коммуникация</li>
                    <li>Развитие управленческой гибкости</li>
                    <li>Поиск форм управления и мотивации подчиненных</li>
                    <li>Как ставить задачи так, чтобы их принимали</li>
                    <li>Передача задачи и ответственности за выполнение</li>
                    <li>Управление конфликтами</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Services
